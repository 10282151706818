import React from 'react';

import styles from './Widget.module.css';

const widget = (props) => {

  return (
    <div className={styles.widget}>
      <div className={styles.widgetHeader}>

      </div>
      <div className={styles.widgetBody}>
        <web-html-element is-widget url={props.url}></web-html-element>
      </div>
    </div>
  );

}

export default widget;
