import React from 'react';

import PageElement from '../PageElement';

import styles from './PageFooter.module.css';

const pageFooter = (props) => {
  if (!props.widgets) {
    return null;
  }

  return (
    <page-footer class={styles.footer}>
      <PageElement {...props} />
    </page-footer>
  );
}

export default pageFooter;
