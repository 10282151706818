import React from 'react';

import PageElement from '../PageElement';

import styles from './PageBody.module.css';

const pageBody = (props) => {
  if (!props.widgets) {
    return null;
  }

  return (
    <page-body class={styles.pageBody}>
      <PageElement {...props} />
    </page-body>
  );
}

export default pageBody;
