import React from 'react';

import * as UnderConstruction from '../containers/errors/underConstruction/UnderConstruction';
import * as NaviBar from '../containers/navigation/NaviBar';

import Spinner from './spinner/Spinner';

const lazyComponent = (props) => {
  let LoadedComponent = null
  if (props.componentKey === 'org.eazyportal.layout.underConstruction') {
    LoadedComponent = React.lazy(() => import('../containers/errors/underConstruction/UnderConstruction'));
  } else if (props.componentKey === 'org.eazyportal.layout.navigation') {
    LoadedComponent = React.lazy(() => import('../containers/navigation/NaviBar'));
  } else {
    LoadedComponent = React.Fragment
  }

  return (
    <React.Suspense fallback={<Spinner />}>
      <LoadedComponent />
    </React.Suspense>
  );

}

export default lazyComponent;
