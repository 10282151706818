import React from 'react';
import { Provider } from 'react-redux';
import { MemoryRouter } from 'react-router';

import ErrorBoundary from './components/ErrorBoundary';
import SitePages from './components/page/SitePages';

import store from './state/store';

class App extends React.Component {

  render() {
    return (
      <React.StrictMode>
        <MemoryRouter>
          <ErrorBoundary>
            <Provider store={store}>
              <SitePages />
            </Provider>
          </ErrorBoundary>
        </MemoryRouter>
      </React.StrictMode>
    );
  }

}

export default App;
