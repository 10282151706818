import React from 'react';

import Error from './page/error/Error';

class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({hasError: true});

    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <Error />;
    }

    return this.props.children;
  }

}

export default ErrorBoundary;
