import { authService } from '@eazyportal/core-ui-lib';

import React from 'react';

import AccessDenied from './AccessDenied';
import InternalServiceError from './InternalServiceError';
import NotFound from './NotFound';

import styles from './Error.module.css';

const error = (props) => {

  const errorCode = props.errorCode || 404;

  let errorContent;
  switch (errorCode) {
    case 401: {
      authService.cleanAuthToken()

      window.location.href = "/"

      break;
    }
    case 403: {
      errorContent = <AccessDenied />
      break;
    }
    case 500 : {
      errorContent = <InternalServiceError />;
      break;
    }
    case 404 :
    default : {
      errorContent = <NotFound />;
      break;
    }
  }

  return (
    <div className={styles.errorWrapper}>
      <div className={styles.noise} />
      <div className={styles.overlay} />
      <div className={styles.terminal}>
        <h1>Error <span className={styles.errorCode}>{errorCode}</span></h1>
        {errorContent}
        <p className={styles.output}>Good luck.</p>
      </div>
    </div>
  );
}

export default error;
