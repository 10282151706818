import { axiosService } from '@eazyportal/core-ui-lib'
import { connect } from 'react-redux';

import React from 'react';

import { setPagesAction } from '../../state/naviBarSlice'
import Error from './error/Error';
import Page from './Page';

class SitePages extends React.Component {

  state = {
    errorProps: null,
    pages: null
  }

  componentDidMount() {
    axiosService.get('/api/pages')
      .then(response => {
        this.props.setPages(response.data);

        this.setState({pages: response.data});
      })
      .catch(error => {
        this.setState({
          errorProps: {
            ...error.response.data,
            errorCode: error.response.status
          }
        });
      });
  }

  getSelectedPage() {
    if (!this.state.pages || (Array.isArray(this.state.pages) && this.state.pages.length === 0)) {
      return null;
    }

    let page;
    if (this.props.selectedPageId) {
      page = this.state.pages.find(page =>
        page.id === this.props.selectedPageId
      );
    }

    return page === undefined ? this.state.pages[0] : page;
  }

  render() {
    if (this.state.errorProps) {
      return (
        <Error {...this.state.errorProps} />
      );
    }

    return (
      <Page page={this.getSelectedPage()} />
    );
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    setPages: (pages) => dispatch(setPagesAction(pages))
  };
};

const mapStateToProps = (state) => {
  return {
    selectedPageId: state.naviBar.selectedPageId
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SitePages);
