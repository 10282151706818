import React from 'react';

import PageElement from '../PageElement';

import styles from './PageHeader.module.css';

const pageHeader = (props) => {
  if (!props.widgets) {
    return null;
  }

  return (
    <page-header class={styles.header}>
      <PageElement {...props} />
    </page-header>
  );
}

export default pageHeader;
