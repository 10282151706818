import React from 'react';

import PageBody from './body/PageBody';
import PageFooter from './footer/PageFooter';
import PageHeader from './header/PageHeader';

export default class Page extends React.Component {

  render() {
    if (!this.props.page) {
      return null;
    }

    return (
      <div>
        <PageHeader widgets={this.props.page.layout.header} />
        <PageBody widgets={this.props.page.layout.body} />
        <PageFooter widgets={this.props.page.layout.footer} />
      </div>
    );
  }

}
