import {
  axiosService,
  authService
} from '@eazyportal/core-ui-lib'

import React from 'react'
import { createRoot } from 'react-dom/client'
import HTMLReactParser, {domToReact} from 'html-react-parser'

const DELETE_TAGS = ['html', 'body'];

class WebHtmlElement extends HTMLElement {

  constructor() {
    super();

    this.state = {
      html: null,
      scriptElements: [],
      styleElements: [],
      url: null
    }
  }

  setUrl(newValue) {
    this.state.url = newValue;
  }

  static get observedAttributes() {
    return ['url'];
  }

  attributeChangedCallback(attributeName, oldValue, newValue) {
    if (attributeName === 'url') {
      this.state.url = newValue;

      axiosService.get(this.state.url)
        .then(html => {
          this.state.html = html.data;

          this.render();
        })
        .catch(error => {
          if ((error.response?.status === 401) && authService.isAuthenticated()) {
            authService.cleanAuthToken()

            window.location.href = "/"
          } else {
            console.log(error)
          }
        });
    }
  }

  connectedCallback() {
    this.state.url = this.getAttribute('url');
  }

  transformFunction = (node) => {
    if ((node.type === 'tag') && (DELETE_TAGS.indexOf(node.name) > -1)) {
      return (
        <React.Fragment>
          {
            domToReact(node.children, {replace: this.transformFunction})
          }
        </React.Fragment>
      )
    }
    else if ((node.type === 'tag') && (node.name === 'head')) {
      Object.values(node.children)
        .map((childNode) => this.transformFunction(childNode));

      return <React.Fragment />
    }
    else if ((node.type === 'tag') && (node.name === 'link')) {
      if ((node.attribs.rel === 'stylesheet') && !node.attribs.href?.endsWith('main.css')) {
        const linkElement = document.createElement('link');

        Object.keys(node.attribs)
          .forEach(key => linkElement.setAttribute(key, node.attribs[key]));

        this.state.styleElements.push(linkElement);
      }

      return <React.Fragment />
    }
    else if ((node.type === 'script') && (node.name === 'script')) {
      const scriptElement = document.createElement('script');

      if (node.attribs.src) {
        scriptElement.src = node.attribs.src;
      }
      else {
        scriptElement.textContent = node.children[0].data;
      }

      scriptElement.async = true;

      this.state.scriptElements.push(scriptElement);

      return <React.Fragment />
    }
  }

  render() {
    if (!this.state.html) {
      return;
    }

    const component = HTMLReactParser(this.state.html, {replace: this.transformFunction});

    const rootElement = document.querySelector("[url='" + this.state.url + "']");

    const webHtmlElementHeader = document.createElement('web-html-element-head')
    rootElement.appendChild(webHtmlElementHeader)

    Object.values([...this.state.scriptElements, ...this.state.styleElements])
      .forEach((element) => webHtmlElementHeader.appendChild(element));

    const webHtmlElementBody = document.createElement('web-html-element-body')
    rootElement.appendChild(webHtmlElementBody)

    createRoot(webHtmlElementBody)
      .render(component);
  }

}

customElements.define('web-html-element', WebHtmlElement);
