import React from 'react';

import styles from './UnderConstruction.module.css';

const underConstruction = (props) => {
  console.log("Gotcha! Come back later and see what changed ;)");

  return (
    <div className={styles.underConstruction}>
      <img alt="under construction" src="/assets/under-construction.png"/>
    </div>
  );
}

export default underConstruction;
