import React from 'react';

import styles from './Spinner.module.css';

const spinner = (props) => {
  return (
    <div className={styles.loaderWrapper}>
      <div className={styles.loader}>
        <div className={styles.face}>
          <div className={styles.circle} />
        </div>
        <div className={styles.face}>
          <div className={styles.circle} />
        </div>
        <div className={styles.loading}>
          Loading...
        </div>
      </div>
    </div>
  );
};

export default spinner;
