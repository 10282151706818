import React from 'react';
import { connect } from 'react-redux';

import { setSelectedPageIdAction } from '../../state/naviBarSlice';

import styles from './NaviBar.module.css';

class NaviBar extends React.Component {

  selectPageHandler = (pageId) => {
    this.props.setSelectedPageId(pageId);
  }

  render() {
    const pageLinks = this.props.pages.map((page, index) => (
      <li className='header-item' key={page.id}>
        <a
          className={
            ((page.id === this.props.selectedPageId) || (!this.props.selectedPageId && index === 0)) ? 'active' : ''
          }
          href='#'
          onClick={() => this.selectPageHandler(page.id)}
        >
          {page.name}
        </a>
      </li>
    ));

    return (
      <nav className={styles.naviBar}>
        <ul className='header-items'>
          {pageLinks}
        </ul>
      </nav>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedPageId: (selectedPageId) => dispatch(setSelectedPageIdAction(selectedPageId))
  };
};

const mapStateToProps = (state) => {
  return {
    pages: state.naviBar.pages,
    selectedPageId: state.naviBar.selectedPageId
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NaviBar);
