import React from 'react';

import styles from './Error.module.css';

const notFound = (props) => (
  <React.Fragment>
    <p className={styles.output}>This is not the page you are looking for.</p>
    <p className={styles.output}>Please try to <a href="/">go back</a> or <a href="/">return to the homepage</a>.</p>
  </React.Fragment>
);

export default notFound;
