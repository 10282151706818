import React from 'react';

import styles from './Error.module.css';

const internalServiceError = (props) => (
  <React.Fragment>
    <p className={styles.output}>Internal Service Error.</p>
    <p className={styles.output}>Please try to refresh the page or <a href="/">return to the homepage</a>.</p>
  </React.Fragment>
);

export default internalServiceError;
