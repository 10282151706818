import React from 'react'

import LazyComponent from '../LazyComponent'
import Widget from '../../containers/widget/Widget'

const pageElement = (props) => {
  return Object.entries(props.widgets)
    .map(([index, widget]) => {
      if (widget.url) {
        return <Widget key={index} url={widget.url} />;
      }
      else if(widget.key) {
        return <LazyComponent
          key={index}
          componentKey={widget.key}
        />;
      }

      console.log("Unexpected widget type:", widget);

      return null;
    });
}

export default pageElement
