import React from 'react';

import styles from './Error.module.css';

const accessDenied = (props) => (
  <React.Fragment>
    <p className={styles.output}>You have no power here...</p>
    <p className={styles.output}>Please try to <a href="/">go back</a> or <a href="/">return to the homepage</a>.</p>
  </React.Fragment>
);

export default accessDenied;
