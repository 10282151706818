import { createSlice } from '@reduxjs/toolkit'

export const naviBarSlice = createSlice({
  name: 'naviBar',
  initialState: {},
  reducers: {
    setPagesAction: (state, action) => {
      state.pages = action.payload
    },
    setSelectedPageIdAction: (state, action) => {
      state.selectedPageId = action.payload
    }
  },
})

export const { setPagesAction, setSelectedPageIdAction } = naviBarSlice.actions

export default naviBarSlice.reducer
