import React from 'react'

import App from './App';

import * as WebHtmlElement from './components/WebHtmlElement'

const Layout = {
  App
}

export default Layout
